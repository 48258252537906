import styled from '@emotion/styled';
import Image from '../../common-components/component-library/Image';
import { colors, spacing } from '../../utils/styleguide';

const LogoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${spacing[5]}px;
  justify-content: 'flex-start';
`;

export default function TrustedByLogoSection({
  logos,
}: {
  logos?: {
    link: string;
    image: {
      url: string;
      fileName: string;
      alt: string;
    };
  }[];
}) {
  return (
    <LogoWrapper>
      {logos?.map((logo, index) => (
        <Logo
          key={logo.image?.url}
          link={logo.link}
          logoUrl={logo.image?.url}
          logoAlt={logo.image?.alt}
          fileName={logo.image?.fileName}
          divider={(index + 1) % logos.length !== 0}
        />
      ))}
    </LogoWrapper>
  );
}

const LogoInnerWrapper = styled.div<{
  divider: boolean;
  disableBottomMargin?: boolean;
}>`
  flex: 1;
  margin-bottom: ${({ disableBottomMargin }) => (disableBottomMargin ? '0' : `${spacing[5]}px`)};
  text-align: center;
  box-sizing: border-box;
  object-fit: contain;
  border-right: ${({ divider }) => (divider ? `1px solid ${colors.grey03}` : 'none')};
`;

const LogoImage = styled(Image)<{ logoHeight?: number; disableBottomMargin?: boolean }>`
  height: ${({ logoHeight }) => (logoHeight ? `${logoHeight}px` : `${spacing[8]}px`)};
  width: auto;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(7%) sepia(0%) saturate(542%) hue-rotate(148deg)
    brightness(98%) contrast(101%);
  opacity: 0.7;
`;

type LogoProps = {
  link?: string;
  logoUrl?: string;
  logoAlt: string;
  fileName?: string;
  divider: boolean;
  logoHeight?: number;
  disableBottomMargin?: boolean;
};

export function Logo({
  link,
  logoUrl,
  logoAlt,
  fileName,
  divider,
  logoHeight,
  disableBottomMargin,
}: LogoProps) {
  if (!logoUrl) {
    return null;
  }

  if (!link) {
    return (
      <LogoInnerWrapper divider={divider} disableBottomMargin={disableBottomMargin}>
        <LogoImage url={logoUrl} fileName={fileName} logoHeight={logoHeight} alt={logoAlt} />
      </LogoInnerWrapper>
    );
  }

  return (
    <LogoInnerWrapper divider={divider} disableBottomMargin={disableBottomMargin}>
      <a href={link}>
        <LogoImage url={logoUrl} fileName={fileName} logoHeight={logoHeight} alt={logoAlt} />
      </a>
    </LogoInnerWrapper>
  );
}
