import styled from '@emotion/styled';
import { TypeContactPageTemplate } from '../../@types/generated';
import Divider from '../common-components/component-library/Divider';
import HubSpotForm from '../common-components/component-library/HubSpotForm';
import TestimonialCard from '../common-components/component-library/TestimonialCard';
import TrustedByLogoSection from '../common-components/component-library/TrustedByLogoSection';
import Container from '../common-components/component-library/layout/Container';
import Typography from '../common-components/component-library/text/Typography';
import { useMediaQuery } from '../utils/hooks/useMediaQuery';
import { breakpointQueries, breakpoints, colors, spacing } from '../utils/styleguide';
import { hasValue } from '../utils/typeCheck';
import Carousel from '../common-components/component-library/carousel/Carousel';

const HeadlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]}px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[6]}px;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${breakpoints.desktop} {
    flex-direction: row;
  }
`;

const TrustedByWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${breakpoints.desktop} {
    width: 50%;
  }
`;

export type ContactPageProps = TypeContactPageTemplate['fields'] & { isContactPage?: boolean };

export default function ContactPage(props: ContactPageProps) {
  const {
    identifier,
    sectionTitle,
    sectionParagraph,
    contactFormSectionTitle,
    contactForm,
    trustedBySectionTitle,
    partnerLogos,
    partnerTestimonials,
  } = props;

  const [isTabletOrLarger] = useMediaQuery(breakpointQueries.tablet);

  const logosToShow = isTabletOrLarger
    ? partnerLogos?.map((logo) => ({
        link: logo?.fields.link ?? '',
        image: {
          url: logo?.fields.image?.fields.file?.url ?? '',
          fileName: logo?.fields.image?.fields.file?.fileName ?? '',
          alt: logo?.fields.image?.fields.description ?? logo?.fields.image?.fields.title ?? '',
        },
      }))
    : partnerLogos?.slice(0, 2).map((logo) => ({
        link: logo?.fields.link ?? '',
        image: {
          url: logo?.fields.image?.fields.file?.url ?? '',
          fileName: logo?.fields.image?.fields.file?.fileName ?? '',
          alt: logo?.fields.image?.fields.description ?? logo?.fields.image?.fields.title ?? '',
        },
      }));

  return (
    <Container backgroundColor={colors.white}>
      <ContentWrapper>
        <HeadlineWrapper>
          <Typography variant="h1">{sectionTitle}</Typography>
          <Typography variant="body">{sectionParagraph}</Typography>
        </HeadlineWrapper>

        <MainContentWrapper>
          <TrustedByWrapper>
            <Typography variant="overlineBold" color={colors.blackSecondary}>
              {trustedBySectionTitle}
            </Typography>
            {partnerLogos?.length !== undefined && <TrustedByLogoSection logos={logosToShow} />}
            {partnerTestimonials?.length !== undefined && (
              <Carousel>
                {partnerTestimonials?.map((testimonial, index) => (
                  <TestimonialCard key={index} testimonial={testimonial} />
                ))}
              </Carousel>
            )}
          </TrustedByWrapper>

          <Divider hideOnSmallDevice vertical css={{ margin: `0 ${spacing[9]}px` }} />
          <Divider hideOnDesktop css={{ margin: `${spacing[5]}px 0` }} />
          <HubSpotForm
            isAvailable={!!contactForm}
            title={contactFormSectionTitle}
            identifier={identifier}
            submitUrl={contactForm?.fields.url}
            submitText={contactForm?.fields.submitText}
            formType={contactForm?.fields.type}
            fields={contactForm?.fields.formItems?.filter(hasValue).map((field) => ({
              fieldName: field.fields.fieldName,
              type: field.fields.type,
              placeholder: field.fields.placeholder,
              required: field.fields.required,
            }))}
            successMessage={contactForm?.fields.successMessage}
          />
        </MainContentWrapper>
      </ContentWrapper>
    </Container>
  );
}
