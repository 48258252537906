import styled from '@emotion/styled';
import { spacing, colors, breakpoints, logoFilters, LogoFilters } from '../../utils/styleguide';
import Image from './Image';
import { css } from '@emotion/react';

const LogoInnerWrapper = styled.div<{ divider: boolean; disablePadding?: boolean }>`
  padding: ${({ disablePadding }) => (disablePadding ? 0 : `${spacing[3]}px 0`)};
  margin-bottom: ${({ disablePadding }) => (disablePadding ? 0 : `${spacing[5]}px`)};
  text-align: center;
  box-sizing: border-box;
  object-fit: contain;
  border-right: ${({ divider }) => (divider ? `1px solid ${colors.grey03}` : 'none')};
`;

const LogoImage = styled(Image)<{ height?: number; color?: LogoFilters }>`
  height: ${({ height }) => height || spacing[5]}px;
  width: auto;
  object-fit: contain;
  filter: ${({ color }) => color && logoFilters[color]};
  opacity: 0.7;
  ${breakpoints.tablet} {
    height: ${({ height }) => height || spacing[6]}px;
  }
`;

const centeredLogos = css`
  flex: 0 1 50%;

  ${breakpoints.tablet} {
    flex: 0 1 33%;
  }
  ${breakpoints.desktop} {
    flex: 0 1 20%;
  }
`;

type LogoProps = {
  link?: string;
  logoUrl?: string;
  fileName?: string;
  divider: boolean;
  alt?: string;
  height?: number;
  disablePadding?: boolean;
  centered?: boolean;
  color?: LogoFilters;
};

export default function Logo({
  link,
  logoUrl,
  fileName,
  divider,
  alt,
  height,
  disablePadding,
  centered = true,
  color = 'blackSecondary',
}: LogoProps) {
  if (!logoUrl) {
    return null;
  }

  if (!link) {
    return (
      <LogoInnerWrapper
        divider={divider}
        disablePadding={disablePadding}
        css={centered && centeredLogos}
      >
        <LogoImage
          url={logoUrl}
          fileName={fileName}
          alt={alt ?? ''}
          maxWidth={200}
          height={height}
          color={color}
        />
      </LogoInnerWrapper>
    );
  }

  return (
    <LogoInnerWrapper
      divider={divider}
      disablePadding={disablePadding}
      css={[centered && centeredLogos]}
    >
      <a href={link}>
        <LogoImage
          url={logoUrl}
          fileName={fileName}
          alt={alt ?? ''}
          maxWidth={200}
          height={height}
          color={color}
        />
      </a>
    </LogoInnerWrapper>
  );
}
