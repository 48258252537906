import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import styled from '@emotion/styled';
import { Entry } from 'contentful';
import { TypeTestimonialSkeleton } from '../../../@types/generated';
import GhostButton from './buttons/GhostButton';
import Typography from './text/Typography';
import { spacing, colors, breakpoints } from '../../utils/styleguide';
import { Logo } from './TrustedByLogoSection';
import { getLinkHref } from '../contentful-elements/util/linkHref';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing[4]}px;
  gap: ${spacing[3]}px;
  border-radius: 2px;
  border: 1px solid ${colors.grey02};
  background: ${colors.grey50};
  height: 300px;
  justify-content: space-between;
  margin: 0 ${spacing[2]}px ${spacing[2]}px ${spacing[2]}px;

  ${breakpoints.tablet} {
    height: 250px;
  }
`;

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[3]}px;
`;

export default function TestimonialCard({
  testimonial,
}: {
  testimonial: Entry<TypeTestimonialSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string> | undefined;
}) {
  if (!testimonial) return null;

  let testimonialAuthor = testimonial.fields.name + ', ' + testimonial.fields.jobTitle;
  if (!testimonial.fields.jobTitle) {
    testimonialAuthor = testimonial.fields.name;
  }

  return (
    <CardWrapper>
      <CopyWrapper>
        <Logo
          divider={false}
          logoUrl={testimonial.fields.companyLogo?.fields.file?.url}
          logoAlt={
            testimonial.fields.companyLogo?.fields.description ??
            testimonial.fields.companyLogo?.fields.title ??
            ''
          }
          fileName={testimonial.fields.companyLogo?.fields.file?.fileName}
          logoHeight={spacing[3]}
          disableBottomMargin
        />
        {testimonial.fields.body && (
          <Typography variant="h6" color={colors.blackSecondary}>
            {documentToPlainTextString(testimonial.fields.body)}
          </Typography>
        )}
        <Typography variant="caption" color={colors.blackSecondary}>
          {testimonialAuthor}
        </Typography>
      </CopyWrapper>

      {testimonial.fields.link && (
        <GhostButton
          label={testimonial.fields.link.fields.identifier}
          trailingIconName="chevron-right"
          color={colors.purple400}
          hideUnderline
          href={getLinkHref(testimonial.fields.link)}
        />
      )}
    </CardWrapper>
  );
}
