import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeFormSkeleton } from './TypeForm';
import type { TypeImageLinkSkeleton } from './TypeImageLink';
import type { TypeMetaHeaderSkeleton } from './TypeMetaHeader';
import type { TypeTestimonialSkeleton } from './TypeTestimonial';

export interface TypeContactPageTemplateFields {
  identifier: EntryFieldTypes.Symbol;
  metaHeader: EntryFieldTypes.EntryLink<TypeMetaHeaderSkeleton>;
  sectionTitle?: EntryFieldTypes.Symbol;
  sectionParagraph?: EntryFieldTypes.Text;
  contactFormSectionTitle?: EntryFieldTypes.Symbol;
  contactForm?: EntryFieldTypes.EntryLink<TypeFormSkeleton>;
  trustedBySectionTitle?: EntryFieldTypes.Symbol;
  partnerLogos?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeImageLinkSkeleton>>;
  partnerTestimonials?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeTestimonialSkeleton>>;
  slug: EntryFieldTypes.Symbol;
}

export type TypeContactPageTemplateSkeleton = EntrySkeletonType<
  TypeContactPageTemplateFields,
  'contactPageTemplate'
>;
export type TypeContactPageTemplate<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeContactPageTemplateSkeleton, Modifiers, Locales>;

export function isTypeContactPageTemplate<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeContactPageTemplate<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'contactPageTemplate';
}
