import { TypeGenericPageTemplate } from '../../@types/generated';
import MainHero from '../common-components/component-library/heroes/MainHero';
import CTASectionLayout from '../common-components/component-library/layout/CTASectionLayout';
import FormCTA from '../common-components/component-library/layout/FormCTA';
import FormSection, {
  FormSectionProps,
} from '../common-components/component-library/layout/FormSection';
import MapMetricsLayout from '../common-components/component-library/layout/MapMetricsLayout';
import PartnerLogosLayout from '../common-components/component-library/layout/PartnerLogosLayout';
import HighlightedProfilesLayout from '../common-components/component-library/layout/highlightedProfilesLayout/HighlightedProfilesLayout';
import { useContentfulMediaOrientation } from '../utils/hooks/useContentfulMediaOrientation';
import Highlight, { HighlightProps } from '../utils/templatePages/Highlight';
import { Document } from '@contentful/rich-text-types';

export type GenericPageProps = {
  isGenericPage: boolean;
  title?: string;
  hero: Partial<{
    title: string;
    paragraph: string;
    image: TypeGenericPageTemplate['fields']['heroImage'];
    buttons: Array<
      Partial<{
        text: string;
        url: string;
      }>
    >;
  }>;
  highlight1: HighlightProps;
  highlight2: HighlightProps;
  highlight3: HighlightProps;
  metrics: Partial<{
    title: string;
    mapItems: Array<{ label: string; locationCodes: string[] }>;
    values: Array<
      Partial<{
        metricName: string;
        metricValue: string;
      }>
    >;
  }>;
  downloadForm: FormSectionProps;
  experts: Partial<{
    sectionTitle: string;
    title: string;
    text: string;
    mainExpertsTitle: string;
    mainExperts: Array<
      Partial<{
        name: string;
        title: string;
        imageUrl: string;
        imageAlt: string;
        description: Document;
      }>
    >;
    secondaryExpertsTitle: string;
    secondaryExperts: Array<
      Partial<{
        name: string;
        title: string;
        imageUrl: string;
        imageAlt: string;
      }>
    >;
  }>;
  callToAction: Partial<{
    sectionTitle: string;
    text: string;
    buttons: Array<{
      label?: string;
      href?: string;
    }>;
    backgroundImageUrl: string;
  }>;

  highlight4: HighlightProps;
  partnerLogos: Partial<{
    title: string;
    logosList: Array<
      Partial<{
        link: string;
        image: {
          url: string;
          name?: string;
          alt: string;
        };
      }>
    >;
  }>;
  contactUs: Partial<{
    sectionTitle: string;
    title: string;
    sections: Array<
      Partial<{
        title: string;
        description: Document;
        link: Partial<{
          title: string;
          url: string;
        }>;
      }>
    >;
  }>;
};

export const GenericPageLayout = function (props: GenericPageProps) {
  const {
    hero,
    highlight1,
    highlight2,
    highlight3,
    highlight4,
    metrics,
    downloadForm,
    experts,
    callToAction,
    partnerLogos,
    contactUs,
  } = props;

  const orientedHeroImage = useContentfulMediaOrientation(hero.image);

  return (
    <>
      <MainHero
        sectionTitle={hero.title}
        paragraph={hero.paragraph}
        imageUrl={orientedHeroImage?.fields.file?.url}
        backgroundImageAlt={orientedHeroImage?.fields.description}
        primaryButtonLabel={hero.buttons?.[0]?.text}
        primaryButtonHref={hero.buttons?.[0]?.url}
        secondaryButtonLabel={hero.buttons?.[1]?.text}
        secondaryButtonHref={hero.buttons?.[1]?.url}
      />

      <Highlight {...highlight1} />

      <FormSection
        id={downloadForm.sectionTitle}
        sectionTitle={downloadForm.sectionTitle}
        title={downloadForm.title}
        description={downloadForm.description}
        form={downloadForm.form}
      />

      <HighlightedProfilesLayout
        id={experts.sectionTitle}
        sectionTitle={experts.sectionTitle}
        title={experts.title}
        paragraph={experts.text}
        primaryProfilesHeadline={experts.mainExpertsTitle}
        primaryProfiles={experts.mainExperts}
        secondaryProfilesHeadline={experts.secondaryExpertsTitle}
        secondaryProfiles={experts.secondaryExperts}
      />

      <Highlight {...highlight2} rightAlignedCopy />

      <Highlight {...highlight3} />

      <MapMetricsLayout
        id={metrics.title}
        mapItems={metrics.mapItems}
        sectionTitle={metrics.title}
        metrics={metrics.values}
      />

      <CTASectionLayout
        id={callToAction.sectionTitle}
        sectionTitle={callToAction.sectionTitle}
        title={callToAction.text}
        buttons={callToAction.buttons}
        backgroundImageUrl={callToAction.backgroundImageUrl}
      />
      <Highlight {...highlight4} rightAlignedCopy />
      <PartnerLogosLayout
        id={partnerLogos.title}
        title={partnerLogos.title}
        logosList={partnerLogos.logosList}
      />
      <FormCTA
        id={contactUs.sectionTitle}
        ctaFormSectionTitle={contactUs.sectionTitle}
        ctaFormTitle={contactUs.title}
        ctaFormSections={contactUs.sections}
      />
    </>
  );
};
